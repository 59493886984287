import React, { Suspense, useEffect, useState } from 'react';
import { MailProvider } from './mod/content/mail/MailContext.js'
import Registration from './state/registration';
import LoginForm from './state/loginForm';
import { Route, Outlet, Routes,  } from 'react-router-dom';
import s from './App.module.css'
import state from './state/state.js';
import axios from 'axios';
import indexedDBConnection from './state/indexedDBConnection.js';


function App() { 

const [isLoading, setIsLoading] = useState(true); // Додаємо стан для відстеження завантаження
useEffect(() => {
  const fetchData = async () => {
      try {
          if (!state.globalId) {
              const userProfileData = await indexedDBConnection.loadData('userProfile');                
              if (userProfileData && userProfileData.length > 0) {
                  const userProfile = userProfileData[0];                    
                  state.tempUserProfile = userProfile;
                  state.globalId = userProfile.id;
                  state.userProfile = userProfile;  
                  state.stateUpdate(); // Отримуємо дані з БД
              } else {
                  console.warn('Не знайдено даних у IndexedDB для userProfile');
              }
          }
          

          const [
              athletesData, 
              sportClubsData, 
              competitionData, 
              mailData, 
              archiveData
          ] = await Promise.all([
              indexedDBConnection.loadData('athletes'),
              indexedDBConnection.loadData('sportClubs'),
              indexedDBConnection.loadData('competition'),
              indexedDBConnection.loadData('mail'),
              indexedDBConnection.loadData('archive')
          ]);

          if (athletesData) {
              state.athletes = athletesData;
          }
          if (sportClubsData) {
              state.sportClubs = sportClubsData;
          }
          if (competitionData) {
              state.competition = competitionData;
          }
          if (mailData) {
              state.mail = mailData;
          }
          if (archiveData) {
              state.archive = archiveData;
          }

      } catch (error) {
          console.error('Помилка при завантаженні даних з IndexedDB:', error);
      } finally {
          setIsLoading(false); // Після завершення завантаження даних
      }
  };

  fetchData();
}, []); // Залежності пусті, щоб викликатися лише при монтуванні компонента

//////////////////////////////////////////////////////////////

//зберігаємо результати змаганнь у випадку невдачного запиту

if (localStorage.getItem('reservResult') !== null){

const reservResult=JSON.parse(localStorage.getItem('reservResult'))
  
        // вносимо результати в профіль турниру
        axios 
        .post(state.api.updateCompetitionResult, {
          results:reservResult.results,
          id:reservResult.competition.id,
          headers: {'Content-Type': 'application/json'}
        })
        .then((response) => {
    
    ///////////////////////заносимо результати в архів///////////////////////////////////////
    const dateForArchive=`${state.formatDate(reservResult.competition.dateStart).slice(0, 2)} - ${state.formatDate(reservResult.competition.dateFinish)}`
    
    axios 
    .post(state.api.saveResultInArchive, {
       title: reservResult.competition.title,
       dateOfCompetition: dateForArchive,
       results:reservResult.results,
      location: reservResult.competition.location,
      secretary:reservResult.competition.secretary,
      mainJudge:reservResult.competition.mainJudge,
        headers: {'Content-Type': 'application/json'}
    })
    .then((response) => {  
      // console.log(response);
       })
    .catch((error) => {
      //  console.log(error);
        });
    //////////////////////////////////////////////////////////////////
          const index = state.competition.findIndex((item)=>{return item.id==reservResult.competition.id})
          state.competition[index].results = JSON.stringify(reservResult.results);
          indexedDBConnection.saveData ('competition', state.competition)          
    //Заносимо результати в профіль спортсмена    
          axios.post(state.api.postCompetitionResultToAthletProfile, { updateData:reservResult. updateData })
          .then(response => { 
            localStorage.removeItem('reservResult')
          })
          .catch(error => {
            console.error('Помилка Axios-запиту, невдалось зберегти результати турнирув профілі атлетів:', error);
          });
               })
        .catch((error) => {   });
}
/////////////////////////////////////////////////////////////////////////////
state.messageCount()  

const DelayedContent = React.lazy(() => import('./mod/content/delay.js'));


return (
  <>
      <MailProvider>
          <Routes>
              <Route path="/registration" element={<Registration />} />
              <Route path="/login/*" element={<LoginForm />} />
              <Route
                  path="*"
                  element={
                      isLoading ? ( // Перевіряємо, чи завершилося завантаження даних
                          <>
                              <div className={s.flag}>
                                  Зачекайте, дані завантажуються...<br /><br />
                                  <img alt='фото' src='/img/main/ukrainian-waving-flag-30.gif' />
                              </div>
                          </>
                      ) : (
                          state.globalId === null ? (
                              <>
                                  <LoginForm />
                                  <Outlet />
                              </>
                          ) : (
                              <Suspense fallback={
                                  <>
                                      <div className={s.flag}>
                                          Зачекайте, дані завантажуються...<br /><br />
                                          <img alt='фото' src='/img/main/ukrainian-waving-flag-30.gif' />
                                      </div>
                                  </>
                              }>
                                  <DelayedContent />
                              </Suspense>
                          )
                      )
                  }
              />
          </Routes>
      </MailProvider>
  </>
);

}

export default App;
