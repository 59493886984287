import React, { useState } from 'react';
import axios from 'axios';
import s from './login.module.css';
import state from './state.js';
import { NavLink, useNavigate } from 'react-router-dom';
import indexedDBConnection from './indexedDBConnection.js';
import lang from './language.js'


function Registration() {
  const navigate = useNavigate();

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [personName, setPersonName] = useState('');
  const [secondName, setSecondName] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');



  const handleUsernameChange = (e) => {
    setUsername(e.target.value.trim());
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value.trim());
  };

  const handlePersonNameChange = (e) => {
    setPersonName(e.target.value.trim());
  };

  const handleSecondNameChange = (e) => {
    setSecondName(e.target.value.trim());
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Перевірка, чи всі поля заповнені
    if (!personName || !secondName || !username || !password) {
      setErrorMessage('Ви не надали всі дані, що необхідні для реєстрації. Будь ласка, заповніть усі поля форми!');
      return; // При незаповнених полях виходимо з функції
    }

    if (password.length < 5) {
      setErrorMessage('Ваш пароль повинен містити більше 4 символів!')
      return
    }
    let encodedPassword
    try { encodedPassword = btoa(password); }
    catch (error) {
      setErrorMessage('Ваш пароль не повинен містити символи кирилиці!')
      return
    }

    //перевірка унікальносты логіна
    axios.get(state.api.getAthletes)
      .then((response) => {
        const athletes = response.data.athletes; // Оновлення стану                         
        const loginTest = athletes.some((item) => item.login === username)
        if (loginTest) {
          setErrorMessage(lang['ukr'].usedLoginByAnotherUser/*Обраний вами логін вже використовується іншим користувачем*/)
        } else {
          // Відправляємо дані на сервер для реєстрації
          axios
            .post(state.api.registration, {
              login: username,
              pass: encodedPassword,
              personName: personName,
              secondName: secondName,
              lastChangesDate:state.lastChangesDateFormat()
            })
            .then((response) => {
              let userProfile
              try {
                userProfile = JSON.parse(response.data.userProfile)
              } catch {
                userProfile = response.data.userProfile
              }
              indexedDBConnection.saveData('userProfile', userProfile);
              state.userProfile = userProfile
              state.tempUserProfile = Object.assign({}, userProfile)
              state.globalId = state.userProfile ? parseInt(state.userProfile.id) : null;
              state.stateUpdate(); // Отримуємо дані з БД
              navigate('/');

              setUsername('');
              setPassword('');
              setPersonName('');
              setSecondName('');


            })
            .catch((error) => {
              console.error('Помилка запиту до сервера:', error);
            });

        }

      })
      .catch((error) => {
        console.error('Помилка запиту до сервера:', error);
        return
      });


  };


  return (
    <div className={s.main}>
      <h2>Реєстрація нового користувача</h2>
      <form onSubmit={handleSubmit}>

        <div>
          <label>Призвище:</label><br />
          <input
            type="text"
            value={secondName}
            onChange={handleSecondNameChange}
            required
          />
        </div>
        <div>
          <label>Ім'я:</label><br />
          <input
            type="text"
            value={personName}
            onChange={handlePersonNameChange}
            required
          />
        </div>
        <div>
          <label>Логін:</label><br />
          <input
            type="text"
            value={username}
            onChange={handleUsernameChange}
            required
          />
        </div>
        <div>
          <label>Пароль:</label><br />
          <input
            type={showPassword ? 'text' : 'password'}
            value={password}
            onChange={handlePasswordChange}
            required
          /><br /><br />
          <button type="button" onClick={toggleShowPassword}>
            {showPassword ? 'Приховати' : 'Показати'} пароль
          </button>
        </div>

        <div>
          <button type="submit">Зареєструватись</button>
        </div>
        <div>
          <div>
            <NavLink to="/login"><button>Повернутись</button></NavLink>
          </div>
          {errorMessage && <p className={s.errorMessage} onClick={() => { setErrorMessage('') }}>
            {errorMessage}</p>}
        </div>
      </form>
    </div>
  );
}



export default Registration;

